.modal-heading {
  color: $brand-color !important;
  font-size: 1.5rem !important;
}

.inputDnD {
  .form-control-file {
    position: relative;
    width: 100%;
    height: 50%;
    min-height: 6em;
    outline: none;
    visibility: hidden;
    cursor: pointer;
    //background-color: #c61c23;

    &:before {
      content: attr(data-title);
      position: absolute;
      top: 0.5em;
      left: 0;
      width: 100%;
      min-height: 5em;
      //line-height: 2em;
      padding-top: 1.5em;
      opacity: 1;
      visibility: visible;
      text-align: center;
      border: 1px solid #cccccc;
      transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
      overflow: hidden;
    }
    &:hover {
      &:before {
        border-style: solid;
        box-shadow: inset 0px 0px 0px 0.25em currentColor;
      }
    }
  }
}

//Switch
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $brand-color;
}

input:focus + .slider {
  box-shadow: 0 0 1px $brand-color;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@-moz-document url-prefix() {
  .inputDnD {
    border: 1px solid #6a6a6a;
    position: relative;
    height: 5rem;

    &:hover {
      border: 3px solid #6a6a6a;
    }

    .mz-drop-overlay {
      top: 33%;
      width: 95%;
      display: block !important;
    }

    .form-control-file {
      visibility: visible;
      text-align: center;
      padding: 34px;
      opacity: 0;
      z-index: 1;
      min-height: 4rem;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)  {
  .inputDnD {
    border: 1px solid #6a6a6a;
    position: relative;
    height: 5rem;

    &:hover {
      border: 3px solid #6a6a6a;
    }

    .mz-drop-overlay {
      top: 33%;
      width: 95%;
      display: block !important;
    }

    .form-control-file {
      visibility: visible;
      text-align: center;
      padding: 34px;
      opacity: 0;
      z-index: 1;
      min-height: 4rem;
    }
  }
}