// Bootstrap
//$primary = #FFA100;
$theme-colors: (
        'primary': #FFA100,
        'release-test': lighten(#FFA100, 40),
        'release-preview': lighten(#FFA100, 20),
        'release-live': #FFA100
);

@import "~bootstrap/scss/bootstrap.scss";

//Datepicker
@import "~@chenfengyuan/datepicker/dist/datepicker.css";

//FontAwesome
@import "~@fortawesome/fontawesome-free/css/all";

// Modules
@import "packages/toastr";

//Custom Styles
@import "custom-theme";
@import "custom-theme-mobile";
@import "modal";
@import "login_page";
@import "datatables.css";

@import "~select2/dist/css/select2.css";
@import "~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css";