$brand-color: #FFA100;

.brand-primary {
  color: $brand-color !important;
}

/**
Body
 */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
Helper classes
 */

.d-hidden {
  display: none;
}

/**
Fixed width helper classes
 */

.w-100px {
  width: 100px;
  flex-basis: 100px;
  flex-shrink: 0;
  flex-grow: 0;
}

.w-120px {
  width: 120px;
  flex-basis: 120px;
  flex-shrink: 0;
  flex-grow: 0;
}

/**
Buttons
 */

.btn-aug {
  background-color: #EEEEEE;
  border: 1px solid #cccccc;

  &.long {
    width: 450px;
  }

  &:hover {
    background: #cfcfcf;
  }
}

/**
Modal
 */
.modal-aug {
  .modal-title {
    color: $brand-color;
  }
}

/**
Form
 */

.label {

}

.explanation {
  font-size: .875rem;
  margin-bottom: .5rem;
}

/**
Loading circle
 */

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}

.lds-roller.black div:after {
  background: #000;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/**
Loading overlay
 */
.loading-overlay {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1055;
  opacity: 0.75;

  .animation-box {
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);

  }
}

/**
Spacer
 */
.custom-spacer {
  padding: 10px;
}

/**
Table
 */
.table-wrapper {
  border: 1px solid #cccccc;

  thead {
    background: #cccccc;
  }

}

//Marker-Area
.marker-area {
  min-height: 150px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  .card {
    display: inline-block !important;
    width: 200px;

    img {
      height: 55px;
    }

    .delete-area {
      cursor: pointer;
    }

    input {
      height: 25px !important;
      text-align: center;
      font-size: 11px;
      margin-bottom: 3px;
    }
  }
}

@media (min-width: 992px) {

  .table-wrapper {

    .table-left {
      min-height: 180px;
    }

    .table-right {
      min-height: 285px;
    }
  }
}